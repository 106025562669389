import { render, staticRenderFns } from "./series.vue?vue&type=template&id=7c670a06&scoped=true&"
import script from "./series.vue?vue&type=script&lang=js&"
export * from "./series.vue?vue&type=script&lang=js&"
import style0 from "./series.vue?vue&type=style&index=0&id=7c670a06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c670a06",
  null
  
)

export default component.exports